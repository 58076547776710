<template>
	<MhRouterView class="DevView view hSpace hSpace--app" :isContentReady="isContentReady">

		<div class="view__inner">

			<br /><strong>DevView.vue</strong><hr /><br />

		</div>

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from './../components/MhRouterView/v3/MhRouterView.vue'

	export default {
		name: 'DevView',
		components: {
			MhRouterView,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {
		},
		data() {
			return {
				isContentReady: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DevView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
